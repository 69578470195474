export default {
    state: {
        showAndHideModalTeses: false,
      },
    
      mutations: {
        setshowAndHideModalTeses(state, newValue){
          state.showAndHideModalTeses = newValue;
        }
      },
    
      actions: {
        updateshowAndHideModalTeses({ commit }, newValue){
          commit("setshowAndHideModalTeses", newValue)
        }
      },
    
      getters: {
        getShowAndHideModalTeses(state) {
          return state.showAndHideModalTeses;
        }
      },
}