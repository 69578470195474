import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Início",
      component: () => import("./views/Home/Home.vue"),
    },
    {
      path: "/home",
      name: "Início",
      component: () => import("./views/Home/Home.vue"),
    },
    {
      path: "/teses",
      name: "Teses",
      component: () => import("./views/Teses/Teses.vue"),
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("./views/Login.vue"),
    },
    {
      path: "/register",
      name: "Registro",
      compenent: () => import("./views/Registrar.vue"),
    },
    {
      path: "/profile",
      name: "Perfil",
      component: () => import("./views/Profile.vue"),
    },
    {
      path: "/relatorios",
      name: "Relatorios",
      component: () => import("./views/Relatorios.vue"),
    },
    {
      path: "/admin",
      name: "Administrador",
      component: () => import("./views/BoardAdmin.vue"),
    },
    {
      path: "/mod",
      name: "Moderator",
      component: () => import("./views/BoardModerator.vue"),
    },
    {
      path: "/user",
      name: "User",
      component: () => import("./views/BoardUser.vue"),
    },
    {
      path: "/calculo/restituicao/:nCodOp",
      name: "Teses/Restituicao/_nCodOp",
      component: () => import("./views/Teses/Restituicao/Restituicao.vue"),
    },
    {
      path: "/calculo/inss/:nCodOp",
      name: "Teses/Inss/_nCodOp",
      component: () => import("./views/Teses/Inss/Inss.vue"),
    }, 
    {
      path: "/calculo/exclusao/:nCodOp",
      name: "Teses/Exclusao/_nCodOp",
      component: () => import("./views/Teses/Exclusao/Exclusao.vue"),
    }, 
    {
      path: "/calculo/insumos/:nCodOp",
      name: "Teses/Insumos/_nCodOp",
      component: () => import("./views/Teses/Insumos/Insumos.vue"),
    }, 
    {
      path: "/calculo/compensacao/:nCodOp/:cnpj/:tipo",
      name: "Teses/Compensacao/_nCodOp/_cnpj/_tipo",
      component: () => import("./views/Teses/Compensacao/Compensacao.vue"),
    }, 
    {
      path: "/listaspeds",
      name: "Teses/Restituicao",
      alias: "/Teses/Restituicao",
      component: () => import("./views/Teses/Restituicao/Listaspeds.vue"),
    }, 
    {
      path: "/listamanads",
      name: "Teses/Inss",
      alias: "/Teses/Inss",
      component: () => import("./views/Teses/Inss/Listamanads.vue"),
    }, 
    {
      path:"/listacompensacao",
      name: "Teses/Compensacao",
      alias: "/Teses/Compensacao",
      component: () => import("./views/Teses/Compensacao/ListaCompensacao.vue")
    },
    {
      path:"/teses/compensacao/listaguias/:nCodOp/:cnpj",
      name: "Teses/Listaguias/_nCodOp/_cnpj",
      alias: "/Teses/ListaGuias",
      component: () => import("./views/Teses/Compensacao/ListaGuias.vue")
    },
    {
      path: "/listainsumos",
      name: "Teses/Insumos",
      alias: "/Teses/Insumos",
      component: () => import("./views/Teses/Insumos/ListaInsumos.vue"),
    }, 
    {
      path: "/listaexclusao",
      name: "Teses/Exclusao",  
      alias: "/Teses/Exclusao",
      component: () => import("./views/Teses/Exclusao/ListaExclusao.vue"),
    }, 
    {
      path: '/manadRubricaPesquisa/:nCodOp/:cnpj',
      name: 'Teses/Inss/_nCodOp',
      component: () => import("./views/Teses/Inss/ManadRubricaPesquisa.vue"),
    },    
        {
      path: "/convertInCsv",
      name: "Teses/ConvertFile",
      component: () => import("./views/Teses/ConvertInCsv/ConvertInCsv.vue"),
    }, 

  ],
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/register", "/home"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});
