import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import VeeValidate from "vee-validate";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueTheMask from "vue-the-mask";
import Money from "v-money";
import VueSweetalert2 from "vue-sweetalert2";
import VueToast from "vue-toast-notification";
import fontAwesomeService from "./services/fontAwesome.service";

import { BootstrapVue } from "bootstrap-vue";
import { vue2Dropzone } from "vue2-dropzone";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-toast-notification/dist/theme-sugar.css";

library.add({ ...fontAwesomeService });

Vue.config.productionTip = false;
Vue.use(VeeValidate);
Vue.use(VueTheMask);
Vue.use(Money, { precision: 4 });
Vue.use(VueSweetalert2);
Vue.use(BootstrapVue);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("vue-dropzone", vue2Dropzone);
Vue.use(VueToast);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
