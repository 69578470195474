<template>
  <section>
    <div id="fundo" class="fundo">
      <div id="main" class="container-fluid" style="padding-top: 1%">
        <router-view />
      </div>
      <Footer></Footer>
    </div>

    <!-- START - MODAL TESES -->
    <b-modal
      ref="teses"
      hide-footer
      @hidden="closeModalTeses"
      title="Digite o código de oportunidade:"
    >
      <b-overlay :show="loading">
        <!-- Start - Teses -->
        <div class="row">
          <!-- Input NCodOp -->
          <div class="col-12 mt-2 d-flex justify-content-center">
            <input
              id="inputNcodRestituicao"
              class="form-control"
              v-model="nCodOp"
              type="text"
              v-on:input="preventLetters($event)"
              @keyup.enter="sendNcodOpToTese()"
            />
            <b-overlay :show="!nCodOp || !tese">
              <template #overlay>
                <div></div>
              </template>
              <button
                @click.stop.prevent="sendNcodOpToTese()"
                class="btn btn-outline-green"
              >
                Buscar
              </button>
            </b-overlay>
          </div>
        </div>
        <!-- End - Teses -->
      </b-overlay>
    </b-modal>
    <!-- END - MODAL TESES -->
  </section>
</template>

<script>
import { CLOSING } from "ws";
import Footer from "./Footer/Footer.vue";

export default {
  name: "ContentSystem",
  components: { Footer },
  data() {
    return {
      nCodOp: "",
      loading: false,
      tese: "",
      optionsTeses: [
        { text: "INSS", value: "inss" },
        { text: "Restituição", value: "restituicao" },
        { text: "Insumos", value: "insumos" },
        { text: "Exclusão", value: "exclusao" },
      ],
    };
  },
  props: {
    openModal: Boolean,
    //tese: "",
  },

  updated() {
    if (this.$route.fullPath != "/login") {
      fundo.style.backgroundImage = "none";
    }
    if (this.$route.fullPath === "/teses/restituicao")
      this.tese = "restituicao";
    if (this.$route.fullPath === "/teses/inss") this.tese = "inss";
    if (this.$route.fullPath === "/teses/insumos") this.tese = "insumos";
    if (this.$route.fullPath === "/teses/exclusao") this.tese = "exclusao";
  },

  computed: {
    isVisibleModalTeses() {
      return this.$store.getters.getShowAndHideModalTeses;
    },
  },

  watch: {
    isVisibleModalTeses(newValue) {
      if (newValue) this.$refs["teses"].show();
    },
  },

  methods: {
    sendNcodOpToTese() {
      this.$refs["teses"].hide();
      this.$router.push(`/calculo/${this.tese}/${this.nCodOp.trim()}/`);

      this.tese = "";
      this.nCodOp = "";
    },

    fetchCompanyList() {
      this.$refs["teses"].hide();
      this.$router.push(`/${this.tese}/lista/`);

      this.tese = "";
      this.nCodOp = "";
    },

    preventLetters(event) {
      let value = event.target.value;
      // Check if value is number
      let isValid = +value == +value;

      if (!isValid) {
        // input event is not cancellable by preventDefault()
        // so we have to use the below approach to reset the current value to previous one
        var resetEvent = document.createEvent("Event");
        resetEvent.initEvent("input", true, true);
        event.target.value = event.target._value;
        event.target.dispatchEvent(resetEvent);
      }
    },

    closeModalTeses() {
      this.$store.dispatch("updateshowAndHideModalTeses", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#main {
  width: 98%;
  min-height: calc(100vh - 130px);
}
</style>
