import axios from "axios";
import emitter from "./emitter.js";
const API_URL = process.env.VUE_APP_URL_API;

class AuthService {
  async login(user) {
    try {
      return await axios
        .post(API_URL + "/login/signin", {
          email: user.username,
          password: user.password,
        })
        .then((resp) => {
          if (resp.status == 200 && resp.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(resp.data));
            return resp.data;
          }
        });
    } catch (err) {
      const msg = `Erro ao logar: ${err.message}`;
      const type = "error";
      emitter.emit("makeModalDefault", { msg, type });
      emitter.emit("returnLogin", false);
      console.error("❌ Error SigIn: ", err.message);
    }
  }

  logout() {
    try {
      localStorage.removeItem("user");
    } catch (err) {
      console.error("❌ Error Logout: ", err.message);
    }
  }

  async register(user) {
    try {
      return await axios
        .post(API_URL + "/signup", {
          username: user.username,
          email: user.email,
          password: user.password,
        })
        .then((resp) => {
          if (resp.status == 201) {
            return resp.data;
          }
        });
    } catch (err) {
      console.error("❌ Error register user: ", err.message);
    }
  }
}

export default new AuthService();
