<template>
  <a target="_blank" href="https://tributojusto.gitbook.io/portalcore/release-notes/o-que-ha-de-novo">
    <footer class="w-100 " id="footer">
      Versão 1.2.7 - Visualizar notas de atualização.
    </footer>
  </a>
</template>

<script>
import Icon from "../Icon/icon.vue";

export default {
  name: "Footer",

  components: {
    Icon,
  },
};
</script>

<style src="./FooterStyle.scss" lang="scss" scoped />
