<!--
    <Icon :iconName='"house"' />
    Change house for the icon name you want.
    Find icons at https://icons.getbootstrap.com/
    Always remove "bi bi-" when copying an icon name.
-->

<template lang="">
    <i class="bi" :class='`bi-${iconName}`'></i>
</template>


<script>
export default {
    props:['iconName']
}
</script>


<style lang="">
    
</style>