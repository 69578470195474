import {
  faHome,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faPaperclip,
  faExternalLinkAlt,
  faSearch,
  faSearchPlus,
  faCheck,
  faSearchMinus,
  faSearchDollar,
  faSearchLocation,
  faFileImport,
  faFileAlt,
  faCartPlus,
  faPlus,
  faExclamationTriangle,
  faPlusCircle,
  faCheckDouble,
  faInfo,
  faInfoCircle,
  faPaperPlane,
  faHistory,
  faArrowLeft,
  faArrowRight,
  faLongArrowAltRight,
  faFileContract,
  faAngleDoubleDown,
  faAngleDoubleRight,
  faChevronDown,
  faChevronRight,
  faFilter,
  faEdit,
  faDownload,
  faEnvelope,
  faKey,
  faShare,
  faCopy,
  faMinus,
  faShareAlt,
  faUpload,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

import {
  faUser,
  faBell,
} from "@fortawesome/free-regular-svg-icons";

export default {
  faHome,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faPaperclip,
  faExternalLinkAlt,
  faSearch,
  faSearchPlus,
  faCheck,
  faSearchMinus,
  faSearchDollar,
  faSearchLocation,
  faFileImport,
  faFileAlt,
  faCartPlus,
  faPlus,
  faExclamationTriangle,
  faPlusCircle,
  faCheckDouble,
  faInfo,
  faInfoCircle,
  faPaperPlane,
  faHistory,
  faArrowLeft,
  faArrowRight,
  faLongArrowAltRight,
  faFileContract,
  faAngleDoubleDown,
  faAngleDoubleRight,
  faChevronDown,
  faChevronRight,
  faFilter,
  faEdit,
  faDownload,
  faEnvelope,
  faKey,
  faShare,
  faCopy,
  faMinus,
  faShareAlt,
  faUpload,
  faTrashCan,
  faUser,
  faBell,
};
