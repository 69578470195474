<template>
  <main id="app">
    <NavBar v-once />
    
    <keepAlive>
      <ContentSystem/>
    </keepAlive>
    <Notification />

    


  </main>
</template>

<script>
import ContentSystem from "@/Components/ContentSystem.vue";
import NavBar from "@/Components/Navbar/NavBar.vue";
import Notification from "@/Components/Notification/Notification.vue";
import Icon from './Components/Icon/icon.vue';
import Footer from './Components/Footer/Footer.vue';
export default {
  name: "App",
  components: {
    ContentSystem,
    NavBar,
    Notification,
    Icon,
    Footer
  },

  data() {
    return {
      returnTeses: false,
    };
  },

  mounted() {
    // Verificação do dispositvo que esta acessando o site
    // if (
    //   navigator.userAgent.match(/Android/i) ||
    //   navigator.userAgent.match(/webOS/i) ||
    //   navigator.userAgent.match(/iPhone/i) ||
    //   navigator.userAgent.match(/iPad/i) ||
    //   navigator.userAgent.match(/iPod/i) ||
    //   navigator.userAgent.match(/BlackBerry/i) ||
    //   navigator.userAgent.match(/Windows Phone/i)
    // ) {
    //   window.location.href = "https://www.google.com";
    // } else {
    //   console.log("teste", false);
    // }
  },
};
</script>

<style src="./styles/AppStyles.scss" lang="scss" />
