<template>
  <div>
    <nav class="navbar navbar-expand p-1" id="navBar">
      <div class="w-100">
        <div class="navbar-nav w-100 d-flex justify-content-between align-items-center">
          <!--Logo Tributo Justo-->
          <div class=""> 
            <router-link to="/home">
              <img src="./../../../public/logo-crop.png" id="logo" alt="Tributo Justo" />
            </router-link>
          </div>
          <!--<div v-if="currentUser" class="navbar-nav text-center w-100 d-flex justify-content-between"> -->

          <!--Menu Central-->
          <div class="d-flex">
            <li class="nav-item" id="home">
              <router-link class="
                  mx-3
                  color-primary
                  text-decoration-none
                  fs-5
                  h-small
                  background-hover" 
                  to="/home">
                Inicio
              </router-link>
            </li>

            <li
              v-if="this.$store.state.auth.user.roles === 'Auditoria' || this.$store.state.auth.user.roles === 'Admin'"
              class="nav-item"> <!--@click="btnModalTeses()">-->
              <router-link class="
                  mx-3
                  color-primary
                  text-decoration-none
                  fs-5
                  h-small
                  background-hover
                  " to="/teses">Teses
              </router-link>
            </li>


            <li class="nav-item" id="listaManads">
            </li>
            
          </div>

          <!--Menu Direito-->
          <div class="d-flex">
            <li class="nav-item">
              <Icon :iconName='"bell"' class="nav-icon"/>
            </li>

            <li class="nav-item">
              <router-link class="
                mx-3
                color-primary
                text-decoration-none
                fs-5
                h5
                border-b-hover" 
                to="/profile">
                <span class="
                  mx-3
                  color-primary
                  text-decoration-none
                  fs-3
                  h6
                  border-b-hover
                  background-green">
                  Auditoria
                </span>
              </router-link>
            </li>

            <li @click="logOut()" class="nav-item">
              <router-link class="
                mx-3
                color-primary
                text-decoration-none
                fs-3
                h6
                border-b-hover
              " to="/sair">
                Sair
              <Icon :iconName='"person"' class="nav-icon" />
              </router-link>
            </li>
          </div>
          <!--</div>-->
        </div>

      </div>

    </nav>
  </div>
</template>

<script>
import Icon from '../Icon/icon.vue';

export default {
  name: "NavBar",
  
  components: {
    Icon
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("Admin");
      }
      return false;
    },

    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("Mod");
      }
      return false;
    },
  },

  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },

    btnModalTeses() {
      this.$store.dispatch("updateshowAndHideModalTeses", true)
    },
  },
};
</script>

























































<style src="./NavBarStyle.scss" lang="scss" scoped />