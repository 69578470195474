<template>
    <div>

    </div>
</template>

<script>
import emitter from '@/services/emitter';
export default {
    mounted() {
        emitter.on("makeToast", (params) => {
            this.makeToast(params.msg, params.type)
        })

        emitter.on("makeModalWithBtnsAndTitle", (params) => {
            this.makeModalWithBtnsAndTitle(params.title, params.text, params.type)
        })

        emitter.on("makeModalDefault", (params) => {
            this.makeModalDefault(params.msg, params.type)
        })
    },
    methods: {
        makeToast(messageError, type) {
            this.$toast.open({
                type: type,
                message: `<strong>${messageError}</strong> <button class="btn btn-outline-light ml-3">X</button>`,
                position: 'bottom-right',
                duration: 0,
            });
        },


        makeModalDefault(message, type) {
            this.$swal({
                type: type,
                icon: type,
                position: "top-center",
                html: message,
                showConfirmButton: false,
                timer: 2100,
            });
        },
        
        makeModalWithBtnsAndTitle(title, text, type) {
            this.$swal({
                icon: type,
                title: title,
                text: text,
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok",
            });
        },
    },
}
</script>

<style lang="scss" scoped>
</style>